(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CountTo = function () {
  function CountTo() {
    _classCallCheck(this, CountTo);

    this.element = document.querySelectorAll('.js-count');
    this.Bigelement = document.querySelectorAll('.js-big-count');
    this.countContainer = document.querySelector('.js-count-container');
    this.window = window;
    this.bindEvents();
  }

  _createClass(CountTo, [{
    key: 'bindEvents',
    value: function bindEvents() {
      var _this = this;

      this.window.addEventListener('scroll', function () {
        var position = _this.countContainer.getBoundingClientRect();

        if (position.top >= 0 && position.bottom <= window.innerHeight) {
          _this.element.forEach(function (el) {
            _this.count(el);
          });
        }
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
          _this.Bigelement.forEach(function (ele) {
            _this.countbig(ele);
          });
        }
      });
    }
  }, {
    key: 'count',
    value: function count(el) {
      var number = parseInt(el.getAttribute('data-count'));
      var current = parseInt(el.innerHTML);

      setInterval(function () {
        if (number >= current) {
          el.innerHTML = current;
          current += 1;
        }
      }, 0);
    }
  }, {
    key: 'countbig',
    value: function countbig(ele) {
      var number = parseInt(ele.getAttribute('data-count'));
      var current = parseInt(ele.innerHTML);

      setInterval(function () {
        if (number >= current) {
          ele.innerHTML = current;
          current += 100000;
        }
      }, 0);
    }
  }]);

  return CountTo;
}();

module.exports = CountTo;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Instantiate MobileNavigation
 * @param  {Node}       wrapper Dom class or dom node
 * @param  {Object}     options Object holding optional parameters
 * @return {undefined}
 */
var MobileNavigation = function () {
  function MobileNavigation(_ref) {
    var burger = _ref.burger,
        overlay = _ref.overlay,
        menu = _ref.menu,
        _ref$options = _ref.options,
        options = _ref$options === undefined ? {} : _ref$options;

    _classCallCheck(this, MobileNavigation);

    this.burgerDOM = document.querySelector(burger);
    // this.overlayDOM = document.querySelector(overlay)
    this.menuDOM = document.querySelector(menu);

    this._bindEvents();
  }

  _createClass(MobileNavigation, [{
    key: "_bindEvents",
    value: function _bindEvents() {
      var _this = this;

      this.burgerDOM.addEventListener("click", function () {
        return _this._activate();
      });
      // this.itemsDOM.forEach(item => item.addEventListener("click", this.boundActivateEvent))
    }
  }, {
    key: "_activate",
    value: function _activate(event) {
      if (this.burgerDOM.classList.contains("is-active")) {
        this.burgerDOM.classList.remove("is-active");
        this.menuDOM.classList.remove("is-active");
        // if(this.overlayDOM) this.overlayDOM.classList.remove("is-active")
      } else {
        this.burgerDOM.classList.add("is-active");
        this.menuDOM.classList.add("is-active");
        // if(this.overlayDOM) this.overlayDOM.classList.add("is-active")
      }
    }
  }]);

  return MobileNavigation;
}();

exports.default = MobileNavigation;

},{}],3:[function(require,module,exports){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* example
    const toggler = new Toggler({
        toggleButton: '.js-toggle-menu',
        dataElId: 'data-elId',
        activeClass: 'is-active',           //optional (default class: 'is-active')
    });
*/

var Toggler = function () {
  function Toggler(args) {
    _classCallCheck(this, Toggler);

    this.toggleButton = document.querySelectorAll(args.toggleButton);
    this.toggleContent = document.querySelectorAll(args.toggleContent);
    this.dataElId = args.dataElId;
    this.activeClass = args.activeClass ? args.activeClass : 'is-active';
    this.bindEvents();
  }

  _createClass(Toggler, [{
    key: 'bindEvents',
    value: function bindEvents() {
      var _this = this;

      this.toggleButton.forEach(function (el) {

        el.addEventListener('click', function () {
          _this.toggle(el);
          el.classList.add(_this.activeClass);
        });
      });
    }
  }, {
    key: 'toggle',
    value: function toggle(el) {
      var _this2 = this;

      this.toggleButton.forEach(function (el) {
        el.classList.remove(_this2.activeClass);
      });
      this.toggleContent.forEach(function (el) {
        el.classList.remove(_this2.activeClass);
      });
      var id = el.getAttribute(this.dataElId);
      id = document.querySelector(id);
      id.classList.add(this.activeClass);
      id.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }]);

  return Toggler;
}();

module.exports = Toggler;

},{}],4:[function(require,module,exports){
'use strict';

var _mobile = require('./modules/mobile.js');

var _mobile2 = _interopRequireDefault(_mobile);

var _toggle = require('./modules/toggle.js');

var _toggle2 = _interopRequireDefault(_toggle);

var _countTo = require('./modules/countTo.js');

var _countTo2 = _interopRequireDefault(_countTo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// lazyload
var lazyload = new LazyLoad({
  elements_selector: ".lazy"
});
// partners
var partners = new Swiper('.partners-slider', {
  loop: false,
  slidesPerView: 1,
  spaceBetween: 30,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false
  },
  breakpoints: {
    640: {
      slidesPerView: 2
    },
    768: {
      slidesPerView: 3
    },
    1024: {
      slidesPerView: 4
    }
  }
});

// header move
window.onscroll = function () {
  move_header();
};
function move_header() {
  var header = document.querySelector('.site-header');
  var sticky = header.offsetTop;

  if (window.pageYOffset > sticky) {
    header.classList.add('in-move');
  } else {
    header.classList.remove('in-move');
  }
}
// mobile
new _mobile2.default({
  burger: ".js-mobile",
  menu: ".js-menu"
});

// toggle ofert
var toggler = new _toggle2.default({
  toggleButton: '.js-tab',
  toggleContent: '.js-tab-content',
  dataElId: 'data-tab',
  activeClass: 'is-active'
});

// countTO numbers
new _countTo2.default();

//animation of scroll
ScrollReveal().reveal('.js-anim-top', {
  origin: "top",
  distance: "100px",
  scale: 1,
  reset: false,
  delay: 400,
  duration: 1500
});
ScrollReveal().reveal('.js-anim-left', {
  origin: "left",
  distance: "100px",
  scale: 1,
  reset: false,
  delay: 400,
  duration: 1500
});
ScrollReveal().reveal('.js-anim-right', {
  origin: "right",
  distance: "100px",
  scale: 1,
  reset: false,
  delay: 400,
  duration: 1500
});

},{"./modules/countTo.js":1,"./modules/mobile.js":2,"./modules/toggle.js":3}]},{},[4]);
